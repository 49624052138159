import React, { useEffect } from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import tutorpoint from "../images/tutorpoint.svg"
import tutorweb from "../images/tutorweb-1.svg"
import clicknpay from "../images/clicknpay.svg"
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa"
import Aos from "aos"
import avater1 from "../images/avaters/avater-1.png"
import avater2 from "../images/avaters/avater-2.png"
import avater3 from "../images/avaters/avater-3.png"
import avater4 from "../images/avaters/avater-4.png"
import avater5 from "../images/avaters/avater-5.png"
import avater6 from "../images/avaters/avater-6.png"
const callouts = [
  {
    name: "Tutor.Point",
    description:
      "La Tutor.Point srls è una società nata e sviluppatasi in ottica digital 4.0 che offre servizi all’impresa (B2B) ma anche al privato (B2C) in numerosi settori del supporto al cittadino nel senso più ampio..",
    imageSrc: tutorpoint,
    imageAlt: "Il progetto",
    href: "http://tutorpointsrls.com/",
  },
  {
    name: "TutorWeb",
    description:
      "La piattaforma TutorWeb è pensato per un mercato Business, come valido supporto e integrazione di un’attività già esistente, per ampliare la gamma dei servizi/prodotti offerti e per fidelizzare o implementare il proprio bacino d’utenza.",
    imageSrc: tutorweb,
    imageAlt: "Il progetto",
    href: "https://tutorweb.it/",
  },
  {
    name: "Click'n'pay",
    description:
      "Tutorweb Click'n'pay è una piattaforma multiservizi e multibrand che ti permette di usufruire di una vasta gamma di prodotti e utilities direttamente da pc o smartphone senza muoverti da casa.",
    imageSrc: clicknpay,
    imageAlt: "Il progetto",
    href: "/",
  },
]
const squadra = [
  {
    name: "John doe",
    recensioni:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit commodi rerum",
    imgUrl: avater1,
  },
  {
    name: "John doe",
    recensioni:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit commodi rerum",
    imgUrl: avater2,
  },
  {
    name: "John doe",
    recensioni:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit commodi rerum",
    imgUrl: avater3,
  },
  {
    name: "John doe",
    recensioni:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit commodi rerum",
    imgUrl: avater4,
  },
  {
    name: "John doe",
    recensioni:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit commodi rerum",
    imgUrl: avater5,
  },
  {
    name: "John doe",
    recensioni:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit commodi rerum",
    imgUrl: avater6,
  },
  {
    name: "John doe",
    recensioni:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit commodi rerum",
    imgUrl: avater2,
  },
  {
    name: "John doe",
    recensioni:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit commodi rerum",
    imgUrl: avater5,
  },
]
const ChiSiamo = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      anchorPlacement: "top-bottom",
    })
  }, [])
  return (
    <Layout>
      <Seo title="Chi Siamo" />
      <section className="min-h-screen bg-gray-50 py-8 flex flex-col justify-center relative overflow-hidden lg:py-12">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  py-12 bg-white shadow-xl shadow-slate-700/10 ring-1 ring-gray-900/5 ">
          <h2 className="mb-6 text-xl md:text-3xl font-extrabold text-sky-900 uppercase tracking-wider">
            Chi Siamo
          </h2>
          <div className="md:text-xl text-base">
            <div className="max-w-2xl mx-auto py-12 lg:max-w-none">
              <div className="">
                <p className="text-sky-700 ">
                  <span className="font-bold">Click'n'pay</span> è il nuovo
                  portale B2C della società Tutor Point srls, creatrice del
                  marchio TutorWeb, pensato appositamente per il consumatore
                  alla ricerca della comodità e della semplicità nella gestione
                  della vita quotidiana, senza muoversi da casa. Esigenza
                  diventata sempre più diffusa e pregnante dopo la pandemia.
                </p>
                <br />
                <p className="text-sky-700 ">
                  <span className="font-bold">Tutorweb</span> , approda sul
                  mercato ICT italiano nel 2017 come piattaforma digitale
                  multiservizi B2B, indirizzata alle imprese già attive e con un
                  proprio core business, che vogliano implementare la loro
                  offerta in termini di servizi e professionalità, anche grazie
                  a dei percorsi di formazione interna, volti a dar vita a nuove
                  figure consulenziali.
                </p>
                <br />
                <p className="text-sky-700 ">
                  Dietro questo ampio e ambizioso progetto c’è l’Associazione
                  Tutor Famiglia e Impresa, nella quale convergono
                  professionisti esperti in materie fiscali, finanziarie,
                  assicurative, ma anche giuridiche e sociali; nata nel 2011, ha
                  l’obiettivo di mettersi al servizio del cittadino, diventando
                  un punto di riferimento per la consulenza nei principali
                  settori che impattano nel quotidiano dell’intera popolazione.
                </p>
                <br />
                <p className="text-sky-700 ">
                  TutorWeb lavora in un’ottica di comarketing con servizi in
                  outsourcing, attraverso il sistema di advertising,
                  selezionando come partners i migliori attori presenti sul
                  mercato nel proprio settore di riferimento in termini di
                  qualità, affidabilità ed efficienza.
                </p>
              </div>
              <h2 className="text-xl md:text-3xl font-extrabold text-sky-900 uppercase tracking-wider pt-12 md:pt-24 md:pb-8">
                I nostri marchi
              </h2>

              <div
                data-aos="fade-up"
                className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6"
              >
                {callouts.map(callout => (
                  <div key={callout.name} className="group relative">
                    <div className="relative flex w-full h-40 bg-sky-50 border border-sky-100 rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-0 sm:h-64 lg:aspect-w-1 lg:aspect-h-0">
                      <img
                        src={callout.imageSrc}
                        alt={callout.imageAlt}
                        className="px-8"
                      />
                    </div>
                    <h3 className="mt-6  text-base font-semibold text-sky-900">
                      <a href={callout.href} target="_blank" rel="noreferrer">
                        <span className="absolute inset-0" />
                        {callout.name}
                      </a>
                    </h3>
                    <p className="text-base text-sky-700">
                      {callout.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/*<section data-aos="fade-up" className="py-24">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center">
                <h2 className="text-xl md:text-3xl font-extrabold text-sky-900 uppercase tracking-wider">
                  la nostra squadra
                </h2>
              </div>

              <div className="mt-10">
                <div className="space-y-10 md:space-y-0 md:grid lg:grid-cols-4 md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                  <>
                    {squadra.map((persona, index) => (
                      <div
                        key={index}
                        className="py-4 px-4 md:py-8 md:px-8 max-w-sm mx-auto bg-white w-full rounded-xl shadow-lg hover:transition ease-in-out duration-300 space-y-2 sm:py-4 sm:flex flex-col sm:items-center sm:space-y-0"
                      >
                        <img
                          className="block mx-auto rounded-full sm:mx-0 sm:shrink-0"
                          src={persona.imgUrl}
                          alt={persona.name}
                        />
                        <div className="text-center">
                          <div className="">
                            <p className="text-xl mt-4 text-black font-semibold">
                              {persona.name}
                            </p>
                            <small>Sviluppatore</small>
                          </div>
                          <ul className="flex justify-center py-2">
                            <li>
                              <a href="/" target="_blank" rel="noreferrer">
                                <FaFacebookSquare className="text-sky-700 text-2xl" />
                              </a>
                            </li>
                            <li>
                              <a href="/" target="_blank" rel="noreferrer">
                                <FaTwitterSquare className="text-sky-700 text-2xl" />
                              </a>
                            </li>
                            <li>
                              <a href="/" target="_blank" rel="noreferrer">
                                <FaLinkedin className="text-sky-700 text-2xl" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </>
                </div>
              </div>
            </div>
          </section>
                    */}
        </div>
      </section>
    </Layout>
  )
}

export default ChiSiamo
